<template>
  <div>
    <div
      id="offcanvasFilters"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      tabindex="-1"
      aria-labelledby="offcanvasFiltersLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasResearchLineLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="plus" /></span> Add section or subsection
        </h3>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body offcanvas-body--view">
        <div class="mb-1">
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est eum obcaecati nulla adipisci porro culpa magni voluptate repellendus, sapiente esse eaque recusandae ab? Nisi commodi dolorem corrupti natus neque sit!</p>
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Name</label>
          <input
            v-model="sectionName"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Parent</label>
          <v-select
            v-model="parent"
            label="name"
            :options="parents"
            :get-option-key="option => option.id"
          />
        </div>
      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          type="button"
          class="btn btn-success mb-1 d-grid w-100"
          @click="createSection"
        >
          Add section or subsection
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>
  <!-- -->
</template>
<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      sectionName: null,
      parent: null,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/newSection',
      parents: 'safeties/parents',
    }),
  },
  async mounted() {
    if (this.parents.length === 0) {
      await this.$store.dispatch('safeties/getParents')
    }
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleNewSection', false)
    },
    async createSection() {
      await this.$store.dispatch('safeties/create', {
        data: {
          name: this.sectionName,
          parent: this.parent,
        },
      })
      await this.$store.dispatch('safeties/getParents')
      this.closeOffcanva()
    },
  },
}
</script>
