<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ hazard.id ? 'Edit' : 'New' }} OSH Document
              </h2>
            </div>
            <div class="mt-1">
              <div class="form-check form-check-success form-switch d-flex align-items-center">
                <input
                  id="publishContent"
                  v-model="hazard.published"
                  type="checkbox"
                  class="form-check-input"
                >
                <label
                  class="form-check-label"
                  for="publishContent"
                ><small class="ms-1">{{ labels['active'] }}</small></label>
              </div>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to occupational Hazards list"
              @click="$router.go(-1)"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="occupational hazards settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Information</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show"> <!--Information -->
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['information.section'] }}</label>
                        <v-select
                          v-model="hazard.section"
                          label="name"
                          :options="sections"
                          :get-option-key="option => option.id"
                          @input="loadChildrens($event)"
                        />
                      </div>
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['information.subsection'] }}</label>
                        <v-select
                          v-model="hazard.subsections"
                          label="name"
                          multiple
                          :options="subsections"
                          :get-option-key="option => option.id"
                          :disabled="!hazard.section || subsections.length == 0"
                        />
                      </div>
                      <div class="mb-1">
                        <a
                          class="btn btn-sm btn-dark"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasAddSection"
                          aria-controls="offcanvasAddSection"
                          @click="openNewSection"
                        ><i data-feather="plus" /> Add new section or subsection</a>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="col-6">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label w-100"
                            >{{ labels['information.title'] }}<span class="badge bg-light-secondary float-end">EN</span></label>
                            <input
                              v-model="hazard.title"
                              type="text"
                              class="form-control"
                            >
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label w-100"
                            >{{ labels['information.description'] }}<span class="badge bg-light-secondary float-end">CAT</span></label>
                            <input
                              v-model="hazard.title_cat"
                              type="text"
                              class="form-control"
                            >
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label w-100"
                        >{{ labels['information.description'] }}<span class="badge bg-light-secondary float-end">EN</span></label>
                        <quill-editor v-model="hazard.description" />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label w-100"
                        >{{ labels['information.description'] }}<span class="badge bg-light-secondary float-end">CAT</span></label>
                        <quill-editor v-model="hazard.description_cat" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <FileCard
              :data="hazard.files_h"
              :cae="hazard.to_cae"
              :hazard="hazard"
              @saveFiles="saveFiles"
              @multipleFilesTrigger="multipleFilesTrigger"
              @changeToCae="changeToCae"
            />

            <Audience
              v-if="!hazard.multiple && !multipleFiles && loggedUser.roles.includes('super-admin')"
              :data="{ ...hazard.audience }"
              :show-cae="hazard.to_cae"
              :all-researchers-aux="hazard.all_researchers"
              @saveAudience="saveAudience"
            />

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Notification</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <p>{{ labels['notification.description'] }}</p>
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['notification.text'] }}</label>
                        <div id="full-wrapper">
                          <div id="full-container">
                            <div class="editor">
                              <quill-editor v-model="hazard.notification_text" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="send" />
            <span class="ms-25 align-middle">Send documentation</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Sending...</span>
          </button>
        </div>
        <NewSection />
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import FileCard from './components/FileCard.vue'
import Audience from './components/Audience.vue'
import NewSection from '../../partials/offcanvas/NewSection.vue'

export default {
  components: {
    vSelect,
    FileCard,
    Audience,
    NewSection,
  },
  data() {
    return {
      hazardId: this.$route.params.id,
      sending: false,
      errors: '',
      multipleFiles: false,
    }
  },
  computed: {
    ...mapGetters({
      hazard: 'labourHazards/labourHazard',
      sections: 'safeties/parents',
      subsections: 'safeties/childrens',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'osh.osh')
    if (this.hazardId) {
      await this.$store.dispatch('labourHazards/fetchHazard', this.hazardId)
    } else {
      await this.$store.dispatch('labourHazards/cleanHazard')
    }

    if (this.sections.length === 0) {
      await this.$store.dispatch('safeties/getParents')
    }

    if (this.$route.query.section) {
      if (this.$route.query.parent) {
        this.hazard.section = this.sections.find(e => e.id == this.$route.query.parent)
        await this.$store.dispatch('safeties/getChildrens', this.$route.query.parent)
        this.hazard.subsections.push(this.subsections.find(e => e.id == this.$route.query.section))
      } else {
        this.hazard.section = this.sections.find(e => e.id == this.$route.query.section)
        await this.$store.dispatch('safeties/getChildrens', this.$route.query.section)
      }
    }
  },
  methods: {
    multipleFilesTrigger(isMultiple) {
      this.multipleFiles = isMultiple
    },
    async save() {
      this.sending = true
      if (this.hazard.files_h.length > 0) {
        try {
          if (this.hazardId) {
            await this.$store.dispatch('labourHazards/update', { id: this.hazardId, data: this.hazard })
          } else {
            await this.$store.dispatch('labourHazards/create', this.hazard)
          }
          this.errors = ''
        } catch (e) {
          this.errors = e.response.data.message
        }

        if (this.errors === '' && this.hazard.id) {
          Vue.swal('It has been saved correctly!', '', 'success').then(() => {
            this.$router.push({ name: 'admin.labour-hazards.index' })
          })
        } else {
          // Vue.swal
        }
      } else {
        Vue.swal('Atleast one file is required', '', 'warning')
      }

      this.sending = false
    },
    async loadChildrens(data) {
      await this.$store.dispatch('safeties/getChildrens', data.id)
    },
    saveFiles(data) {
      this.hazard.files_h = data
    },
    saveAudience(data) {
      this.hazard.audience = data
    },
    openNewSection() {
      this.$store.dispatch('modals/toggleNewSection', true)
    },
    changeToCae(cae) {
      this.hazard.to_cae = cae
    },
  },
}
</script>
